import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { AppLogo, CloseButton, InputPassword } from 'components';
import { WEB_TITLE } from 'env';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signIn } from 'reducers/profileSlice';
import { authRoute } from 'routes';
import { authService } from 'services';

const RegisterScreen = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });
  const { password } = watch();

  const { mutate: register, isLoading } = useMutation(authService.register, {
    onSuccess: (data, vars) => {
      enqueueSnackbar('Đăng ký thành công', { variant: 'success' });

      authService.login(vars).then(({ tokens, player }) => {
        dispatch(
          signIn({
            accessToken: tokens.access.token,
            refreshToken: tokens.refresh.token,
            ...player,
          }),
        );
      });
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      register(values);
    })();
  };

  return (
    <>
      <CloseButton onClick={() => navigator(authRoute.login.url, { replace: true })} />

      <div className='h-[120px] flex flex-col justify-center items-center w-[70%] relative mt-[40px]'>
        <div className='flex justify-center items-center space-x-2'>
          <AppLogo />
          <span className='font-[Lemonada] text-[28px] text-white'>{WEB_TITLE}</span>
        </div>
        <div className='text-[24px] text-white'>Đăng ký</div>
        <img
          src={require('assets/images/Auth.Register.png')}
          className='absolute top-[-10px] right-[-80px] w-[120px]'
        />
      </div>

      <div className='flex flex-col space-y-10 bg-white rounded-[24px] px-[24px] py-[36px] mx-[20px] pt-[60px]'>
        <Controller
          name='username'
          defaultValue=''
          control={control}
          rules={{
            required: 'Tài khoản không được để trống',
            minLength: { value: 6, message: 'Tài khoản có ít nhất 6 ký tự' },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              variant='standard'
              label='Tài khoản'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='password'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu không được để trống',
            minLength: { value: 6, message: 'Mật khẩu có ít nhất 6 ký tự' },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputPassword
              {...field}
              fullWidth
              variant='standard'
              label='Mật khẩu'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='passwordConfirm'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu không hợp lệ',
            validate: {
              match: (value) => value === password || 'Mật khẩu không khớp',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputPassword
              {...field}
              fullWidth
              variant='standard'
              label='Xác nhận mật khẩu'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <LoadingButton
          color='info'
          variant='contained'
          className='rounded-full w-[80%] mx-auto'
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          Đăng ký
        </LoadingButton>

        <div className='flex justify-center mt-[-20px]'>
          <Link className='font-bold text-info-main hover:text-info-dark' to={authRoute.login.url}>
            Đăng nhập
          </Link>
        </div>
      </div>
    </>
  );
};

export default RegisterScreen;
