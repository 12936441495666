import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { AppLogo, InputPassword } from 'components';
import { WEB_TITLE } from 'env';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signIn } from 'reducers/profileSlice';
import { authRoute } from 'routes';
import { authService } from 'services';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: login, isLoading } = useMutation(authService.login, {
    onSuccess: ({ tokens, player }) => {
      enqueueSnackbar('Đăng nhập thành công');
      dispatch(
        signIn({
          accessToken: tokens.access.token,
          refreshToken: tokens.refresh.token,
          ...player,
        }),
      );
    },
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      login(values);
    })();
  };

  return (
    <>
      <div className='h-[140px] flex flex-col justify-center items-center w-[60%] relative'>
        <div className='flex justify-center items-center space-x-2'>
          <AppLogo />
          <span className='font-[Lemonada] text-[28px] text-white'>{WEB_TITLE}</span>
        </div>
        <div className='text-[24px] text-white'>Đăng nhập</div>
        <img src={require('assets/images/Auth.Login.png')} className='absolute top-[75px] right-[-140px] w-[180px]' />
      </div>
      <div className='flex-1 flex flex-col space-y-10 bg-white rounded-t-[24px] px-[24px] py-[36px] mx-[24px]'>
        <Controller
          name='username'
          defaultValue=''
          control={control}
          rules={{
            required: 'Tài khoản không được để trống',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              variant='standard'
              label='Tài khoản'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='password'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu không được để trống',
          }}
          render={({ field, fieldState: { error } }) => (
            <InputPassword
              {...field}
              fullWidth
              variant='standard'
              label='Mật khẩu'
              onKeyDown={handleKeyDown}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <LoadingButton
          color='info'
          variant='contained'
          className='rounded-full w-[80%] mx-auto'
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          Đăng nhập
        </LoadingButton>

        <div className='flex justify-center space-x-2 mt-[-20px]'>
          <span>Bạn chưa có tài khoản?</span>
          <Link className='font-bold text-info-main hover:text-info-dark' to={authRoute.register.url}>
            Đăng ký
          </Link>
        </div>

        <div className='mt-[20px] mx-[20px]'>
          <img src={require('assets/images/App.Trademark.png')} />
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
