import { AccountBalance, Add, ArrowForwardIos, LockReset, Logout, Remove } from '@mui/icons-material';
import { Button, Dialog, Divider, Paper } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { formatBalance } from 'utils/common';
import { PopupBanking, PopupDeposit, PopupLogout, PopupPassword, PopupWithdraw } from './components';

const Member = () => {
  const { incId, balance } = useSelector(profileSelector);

  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);

  const [openBanking, setOpenBanking] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);

  return (
    <div className='flex flex-col w-full'>
      <div className='min-h-[60px] flex justify-center items-center'>
        <span className='font-bold text-xl text-white'>Tài khoản</span>
      </div>

      <Paper
        className='flex flex-col items-stretch space-y-4 rounded-[24px] p-[16px] mx-[12px] z-10 text-white'
        style={{ background: `url(${require('assets/images/Cover.Background.png')})` }}
      >
        <div className='font-bold'>ID: {incId}</div>
        <div className='text-center mt-3'>
          <div>Số dư tài khoản</div>
          <div className='font-bold text-[48px]'>{formatBalance(balance)}</div>
        </div>
      </Paper>

      <div className='flex-1 bg-white p-[12px] mt-[-80px] pt-[80px]'>
        <div className='h-[80px] flex items-stretch space-x-6 mx-6 my-6'>
          <Button
            fullWidth
            variant='contained'
            color='secondary'
            className='rounded-[24px] flex flex-col items-center'
            onClick={() => setOpenDeposit(true)}
          >
            <Add />
            <div>Nạp tiền</div>
          </Button>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            className='rounded-[24px] flex flex-col items-center'
            onClick={() => setOpenWithdraw(true)}
          >
            <Remove />
            <div>Rút tiền</div>
          </Button>
        </div>

        <Divider />
        <Button
          fullWidth
          className='h-[60px] text-neutral hover:bg-black/5 rounded-none flex justify-between px-3'
          onClick={() => setOpenBanking(true)}
        >
          <div className='flex items-center space-x-2'>
            <AccountBalance /> <span>Liên kết ngân hàng</span>
          </div>
          <ArrowForwardIos fontSize='small' />
        </Button>

        <Divider />
        <Button
          fullWidth
          className='h-[60px] text-neutral hover:bg-black/5 rounded-none flex justify-between px-3'
          onClick={() => setOpenPassword(true)}
        >
          <div className='flex items-center space-x-2'>
            <LockReset /> <span>Đổi mật khẩu</span>
          </div>
          <ArrowForwardIos fontSize='small' />
        </Button>

        <Divider />
        <Button
          fullWidth
          className='h-[60px] text-neutral hover:bg-black/5 rounded-none flex justify-between px-3'
          onClick={() => setOpenLogout(true)}
        >
          <div className='flex items-center space-x-2'>
            <Logout /> <span>Đăng xuất</span>
          </div>
          <ArrowForwardIos fontSize='small' />
        </Button>
        <Divider />
      </div>

      <Dialog open={openDeposit} onClose={() => setOpenDeposit(false)}>
        <PopupDeposit onClose={() => setOpenDeposit(false)} />
      </Dialog>
      <Dialog open={openWithdraw} fullScreen>
        <PopupWithdraw onClose={() => setOpenWithdraw(false)} />
      </Dialog>

      <Dialog open={openBanking} fullScreen>
        <PopupBanking onClose={() => setOpenBanking(false)} />
      </Dialog>
      <Dialog open={openPassword} fullScreen>
        <PopupPassword onClose={() => setOpenPassword(false)} />
      </Dialog>
      <Dialog open={openLogout} onClose={() => setOpenLogout(false)}>
        <PopupLogout onClose={() => setOpenLogout(false)} />
      </Dialog>
    </div>
  );
};

export default Member;
