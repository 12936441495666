import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Paper, TextField } from '@mui/material';
import { CloseButton } from 'components';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { authService } from 'services';

type PopupProps = PopupController & {};

const PopupPassword = ({ onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });
  const { newPassword } = watch();

  const { mutate: changePassword, isLoading } = useMutation(authService.changePassword, {
    onSuccess: () => {
      enqueueSnackbar('Đổi mật khẩu thành công');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      changePassword(values);
    })();
  };

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Đổi mật khẩu</DialogTitle>
      <DialogContent className='flex flex-col p-0'>
        <div className='flex justify-center h-[180px] pb-[20px] z-10'>
          <img src={require('assets/images/User.LockReset.png')} />
        </div>

        <Paper className='flex-1 flex flex-col space-y-6 rounded-[24px] p-[16px] m-[12px] mt-[-80px] pt-[80px]'>
          <Controller
            name='oldPassword'
            defaultValue=''
            control={control}
            rules={{
              minLength: { value: 6, message: 'Mật khẩu không hợp lệ' },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                variant='standard'
                label='Mật khẩu cũ'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name='newPassword'
            defaultValue=''
            control={control}
            rules={{
              required: 'Mật khẩu không được để trống',
              minLength: { value: 6, message: 'Mật khẩu có ít nhất 6 ký tự' },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                variant='standard'
                label='Mật khẩu mới'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name='passwordConfirm'
            defaultValue=''
            control={control}
            rules={{
              required: 'Mật khẩu không hợp lệ',
              validate: {
                match: (value) => value === newPassword || 'Mật khẩu không khớp',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                variant='standard'
                label='Xác nhận mật khẩu'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          <DialogActions className='flex-1 items-end'>
            <LoadingButton
              variant='contained'
              color='info'
              className='w-[200px] rounded-full'
              loading={isLoading}
              onClick={handleClickSubmit}
            >
              Đổi mật khẩu
            </LoadingButton>
          </DialogActions>
        </Paper>
      </DialogContent>
    </>
  );
};

export default PopupPassword;
