import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { AppBar, IconButton } from '@mui/material';
import { AppMenu } from 'containers';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { newBalance, profileSelector } from 'reducers/profileSlice';
import { privateRoute } from 'routes';
import { authService } from 'services';
import { formatBalance } from 'utils/common';

const Footer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { incId, ...profile } = useSelector(profileSelector);
  const [showBalance, setShowBalance] = useState(true);

  const { data: balance, refetch } = useQuery(
    ['profile.balance'],
    () =>
      authService.getProfile().then(({ balance }) => {
        dispatch(newBalance(balance!));
        return balance;
      }),
    {
      initialData: profile.balance,
      refetchInterval: 30 * 1000,
    },
  );

  const isFooter = ![privateRoute.member.path, privateRoute.transaction.path].includes(location.pathname);

  return (
    <AppBar
      component='footer'
      className='left-0 right-0 bottom-0 top-[unset] rounded-t-[20px] max-w-[600px] mx-auto bg-white'
    >
      {isFooter && (
        <div className='h-[40px] absolute left-0 right-0 top-[unset] bottom-[68px] bg-[#465A7A] rounded-full mx-2'>
          <div className='flex justify-between items-center px-[16px] font-bold text-white'>
            <div>Số dư tài khoản ID: {incId}</div>
            <div className='flex items-center'>
              <div className='text-secondary-main'>
                {formatBalance(balance)
                  .split('')
                  .map((number, index) => (
                    <span key={index}>{showBalance ? number : '*'}</span>
                  ))}
              </div>
              <IconButton
                className='text-white/80'
                onClick={() => {
                  refetch();
                  setShowBalance((prev) => !prev);
                }}
              >
                {showBalance ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
              </IconButton>
            </div>
          </div>
        </div>
      )}
      <AppMenu />
    </AppBar>
  );
};

export default Footer;
