import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField } from '@mui/material';
import { CloseButton, InputNumber } from 'components';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { authService, transactionService } from 'services';
import { PopupBanking } from '.';

type PopupProps = PopupController & {};

const PopupWithdraw = ({ onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [openBanking, setOpenBanking] = useState(false);

  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const { data: profile, isSuccess } = useQuery(['authService.getProfile'], () => authService.getProfile(), {
    staleTime: 0,
  });

  const { mutate: createWithdraw, isLoading } = useMutation(transactionService.createWithdraw, {
    onSuccess: () => {
      enqueueSnackbar('Đặt lệnh rút tiền thành công');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createWithdraw(values);
    })();
  };

  const isLink = isSuccess && !profile?.bankAccountNumber;

  return (
    <div className={'flex flex-col ' + (isLink ? '' : 'bg-[#465A7A] min-h-screen')}>
      <CloseButton onClick={onClose} />
      <DialogTitle>Rút tiền</DialogTitle>
      {isLink ? (
        <DialogContent className='p-0'>
          <div className='flex justify-center h-[160px] py-2'>
            <img src={require('assets/images/User.BankMissing.png')} />
          </div>

          <Paper className='space-y-6 rounded-[24px] p-[16px] m-[12px] z-10 mt-[-80px] pt-[80px] pb-[40px]'>
            <div className='mt-[40px]'>
              <div className='text-error text-center mb-3'>* Chưa liên kết ngân hàng</div>
              <div className='flex justify-center'>
                <Button
                  variant='contained'
                  color='info'
                  className='w-[240px] rounded-full'
                  onClick={() => setOpenBanking(true)}
                >
                  Liên kết ngay
                </Button>

                <Dialog open={openBanking} fullScreen>
                  <PopupBanking onClose={() => setOpenBanking(false)} />
                </Dialog>
              </div>
            </div>
          </Paper>
        </DialogContent>
      ) : (
        <DialogContent className='flex flex-col p-0'>
          <div className='flex justify-center h-[160px] py-0 z-10'>
            <img src={require('assets/images/User.AccountBalance.png')} />
          </div>

          <Paper className='flex-1 flex flex-col space-y-6 rounded-[24px] p-[16px] m-[12px] mt-[-80px] pt-[80px]'>
            <Controller
              control={control}
              name='amount'
              defaultValue=''
              rules={{
                required: 'Số tiền rút không được để trống',
                min: { value: 1, message: 'Số tiền rút phải lớn hơn 0' },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='standard'
                  label='Số tiền rút'
                  InputProps={{
                    inputComponent: InputNumber,
                    inputProps: { maxLength: 12 },
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <div className='mt-[40px]'>
              <div className='text-neutral bg-black/5 rounded-[24px]'>
                <div className='font-bold border-b px-[24px] py-[20px]'>Thông tin ngân hàng</div>
                <div className='px-[24px] py-[20px] space-y-1'>
                  <div>{profile?.bankName}</div>
                  <div>{profile?.bankAccountNumber}</div>
                  <div>{profile?.bankAccountHolder}</div>
                </div>
              </div>
            </div>

            {!isLink && (
              <DialogActions className='flex-1 items-end'>
                <LoadingButton
                  variant='contained'
                  color='info'
                  className='w-[200px] rounded-full'
                  loading={isLoading}
                  onClick={handleClickSubmit}
                >
                  Gửi yêu cầu
                </LoadingButton>
              </DialogActions>
            )}
          </Paper>
        </DialogContent>
      )}
    </div>
  );
};

export default PopupWithdraw;
